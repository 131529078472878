import { useZenniFetch } from '../../composables/useZenniFetch'
import type { StoreGetStoreResponse } from '../../types/redemption'

export const useUserClientStores = () => {
  const getOne = (storeId: string) => {
    return useZenniFetch<StoreGetStoreResponse>(
      `/redemption/api/store/${storeId}`,
      {
        method: 'GET',
      },
    )
  }

  const getAll = () => {
    return useZenniFetch<StoreGetStoreResponse[]>('/redemption/api/store', {
      method: 'GET',
    })
  }

  const switchStore = (storeId: string) => {
    return useZenniFetch<any>(`/redemption/api/store/${storeId}/switch`, {
      method: 'POST',
      body: {},
    })
  }

  return {
    getOne,
    getAll,
    switchStore,
  }
}
