import type {
  UseFulfillmentOrder,
  OrderDetailsProps,
  FulfillmentOrderForSitePage,
  UseFulfillmentOrderState,
  UseFulfillmentOrderReturn,
} from './useFulfillmentOrder'

import { useState, toRefs, onMounted } from '#imports'
import { useOrderApi } from '@/composables/api/useOrder'
import { orderDetailsNormalize } from '@/utils/normalize/orderDetailsNormalize'
import { Logger } from '@/utils/logger'

export const useFulfillmentOrder: UseFulfillmentOrderReturn =
  (): UseFulfillmentOrder => {
    const state = useState<UseFulfillmentOrderState>(
      'useFulfillmentOrderDetails',
      () => ({
        data: null,
        loading: false,
      }),
    )
    const emailToken = useState<string | null>('emailToken', () => null)
    onMounted(() => {
      const token = sessionStorage.getItem('emailToken')
      if (token) {
        emailToken.value = token
      }
    })

    const {
      fetchOrderFulfillmentDetails,
      fetchStoreOrderFulfillmentDetails,
      fetchBackendOrderConfirmation,
      fetchBackendOrderDetails,
    } = useOrderApi()

    const getOrder = async (
      siteCode: FulfillmentOrderForSitePage,
      orderId: string,
      storeCode: string,
      storeId?: string,
    ) => {
      try {
        const { data: fulfillmentData, error: fulfillmentError } =
          siteCode === 'confirmation'
            ? ((await fetchOrderFulfillmentDetails(
                orderId,
                storeCode ?? '',
              )) as any)
            : ((await fetchStoreOrderFulfillmentDetails(
                orderId,
                storeId ?? '',
                emailToken.value as string,
              )) as any) // This get Detail order endpoint doesn't use any guest store data, so it's safe to use it

        const { data: orderForBackendData, error: orderForBackendError } =
          siteCode === 'confirmation'
            ? ((await fetchBackendOrderConfirmation(orderId)) as any)
            : ((await fetchBackendOrderDetails(
                orderId,
                storeId ?? '',
                emailToken.value as string,
              )) as any) // This get Detail order endpoint doesn't use any guest store data, so it's safe to use it

        if (fulfillmentError.value || orderForBackendError.value) {
          return null
        }

        return orderDetailsNormalize(
          fulfillmentData.value,
          orderForBackendData.value,
        )
      } catch (err) {
        Logger.error('useFulfillmentOrder/getOrder', err)
        return null
      }
    }

    const loadOrderDetails = async (
      siteCode: FulfillmentOrderForSitePage,
      orderId: string,
      isEcpOrderStoreCode: boolean,
      storeCode: string,
      storeId?: string,
    ) => {
      state.value.loading = true

      try {
        const orderDetails = (await getOrder(
          siteCode,
          orderId,
          storeCode,
          storeId ?? '',
        )) as unknown as OrderDetailsProps

        const data = {
          ...orderDetails,
          giftType: isEcpOrderStoreCode ? 'freeItem' : orderDetails?.giftType,
        }

        state.value.data = data

        return data
      } catch (err) {
        Logger.error('useFulfillmentOrder/loadOrderDetails', err)
        return null
      } finally {
        state.value.loading = false
      }
    }

    const setEmailToken = (token: string) => {
      emailToken.value = token
      sessionStorage.setItem('emailToken', token)
    }
    const clearEmailToken = () => {
      emailToken.value = null
      sessionStorage.removeItem('emailToken')
    }

    return {
      getOrder,
      loadOrderDetails,
      setEmailToken,
      clearEmailToken,
      ...toRefs(state.value),
      emailToken,
    }
  }

export * from './useFulfillmentOrder'
