import { client, hostedFields } from 'braintree-web'
import { defineNuxtPlugin, useState, useRuntimeConfig } from '#imports'
import type { HostedFieldsField } from 'braintree-web/hosted-fields'

export interface Field extends HostedFieldsField {
  error?: string
}

export interface HostedFieldFieldOptions {
  cardholderName?: Field | undefined
  cvv?: Field | undefined
  expirationDate?: Field | undefined
  expirationMonth?: Field | undefined
  expirationYear?: Field | undefined
  number?: Field | undefined
  postalCode?: Field | undefined
}

export interface HostedFieldsCreateOptions {
  authorization?: string | undefined
  fields: HostedFieldFieldOptions
  styles?: any
}

export interface GooglePaymentCreateOptions {
  authorization?: string
  useDeferredClient?: boolean
  googlePayVersion?: number
  googleMerchantId?: string
}

export interface GooglePaymentClientCreateOptions {
  paymentDataCallbacks?: google.payments.api.PaymentDataCallbacks
}

export default defineNuxtPlugin(async () => {
  const braintreeClient = useState<braintree.Client>()

  const runtimeConfig = useRuntimeConfig()
  braintreeClient.value = await client.create({
    authorization: runtimeConfig.public.braintreeClientToken,
  })

  const hostedFieldsInstance = (options: HostedFieldsCreateOptions) => {
    return hostedFields.create({
      ...options,
      client: braintreeClient.value,
    })
  }

  return {
    provide: {
      hostedFieldsInstance,
    },
  }
})
