export const estimationShippingArrivalTime = {
  standard: {
    min: 10,
    max: 14,
  },
  expedited: {
    min: 7,
    max: 12,
  },
}

export const formatDaysLater = (
  afterDays: number,
  from: Date = new Date(),
  format: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  },
) => {
  const date = new Date()
  date.setDate(from.getDate() + afterDays)
  return date.toLocaleDateString('en-US', format)
}
