import { useMagentoCookieState } from '../useMagentoCookieState'
import { useUserStore } from '@/store/user'
import { useZenniFetch } from '@/composables/useZenniFetch'

export const useOrderApi = () => {
  const pay = async (params: any): Promise<any> => {
    const {
      cart,
      vsfCustomer,
      storeCode,
      payment,
      cartId,
      patient,
      legalUser,
    } = params
    return await useZenniFetch(`/platform/api/payment/checkout/pay`, {
      method: 'POST',
      body: {
        cartId,
        cart,
        storeCode,
        vsfCustomer,
        legalUser,
        payment,
        patient,
      },
    })
  }

  const redemptionEmployerPay = async (params: any): Promise<any> => {
    const { cart } = params
    const storeCode = useMagentoCookieState().getStore()
    const userStore = useUserStore()
    const backendToken = userStore.userToken
    return await useZenniFetch(
      '/platform/api/payment/checkout/redemption-employer-pay',
      {
        method: 'POST',
        headers: {
          'x-authorization-obo': `Bearer ${backendToken}`,
          'x-zenni-store-id': userStore.store?.storeId ?? '',
        },
        body: {
          storeCode,
          disableSmsNotification: true,
          cart,
        },
      },
    )
  }

  const fetchOrderFulfillmentDetails = async (
    orderId: string,
    storeCode: string,
  ) => {
    return await useZenniFetch(
      `/platform/api/order/fulfillment/order/${storeCode}/${orderId}`,
    )
  }

  const fetchStoreOrderFulfillmentDetails = async (
    orderId: string,
    storeId: string,
    emailToken: string,
  ) => {
    return await useZenniFetch(
      `/orderDetail/api/order/fulfillment/order?id=${orderId}`,
      {
        method: 'GET',
        headers: {
          'x-zenni-store-id': storeId,
          authorization: `Bearer ${emailToken}`,
        },
      },
    )
  }

  const fetchBackendOrderConfirmation = async (orderId: string) => {
    return await useZenniFetch(`/platform/api/order/order/${orderId}`)
  }

  const fetchBackendOrderDetails = async (
    orderId: string,
    storeId?: string,
    emailToken?: string,
  ) => {
    return await useZenniFetch(`/orderDetail/api/order/order?id=${orderId}`, {
      method: 'GET',
      headers: {
        'x-zenni-store-id': storeId ?? '',
        authorization: `Bearer ${emailToken}`,
      },
    })
  }

  const verifyEmail = async (
    id: string,
    orderId: string | null,
    email: string,
    storeId: string,
  ) => {
    return await useZenniFetch(`/api/verify-email`, {
      method: 'POST',
      headers: {
        'x-zenni-store-id': storeId ?? '',
      },
      body: {
        id,
        globalOrderId: orderId,
        emailAddress: email,
      },
    })
  }

  return {
    redemptionEmployerPay,
    pay,
    fetchOrderFulfillmentDetails,
    fetchStoreOrderFulfillmentDetails,
    fetchBackendOrderConfirmation,
    fetchBackendOrderDetails,
    verifyEmail,
  }
}
