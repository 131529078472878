import { useZenniFetch } from '../useZenniFetch'
import {
  LoginType,
  type OtpStartReqInput,
  type OtpLoginResBody,
  type OtpLoginReqInput,
  type GuestLoginReqInput,
} from '@/types/magicLink'

export const useOtp = () => {
  const start = (params: OtpStartReqInput) => {
    params.loginType = params.userEmail
      ? LoginType.OTP_START
      : params.userPhoneNumber
      ? LoginType.OTP_SMS_START
      : undefined

    return useZenniFetch(`/redemption/api/user/login`, {
      method: 'POST',
      body: params,
    })
  }

  const verify = (params: OtpLoginReqInput) => {
    params.loginType = params.userEmail
      ? LoginType.OTP_LOGIN
      : params.userPhoneNumber
      ? LoginType.OTP_SMS_LOGIN
      : undefined

    return useZenniFetch<OtpLoginResBody>(`/redemption/api/user/login`, {
      method: 'POST',
      body: params,
    })
  }

  const guestLogin = (params: GuestLoginReqInput) => {
    params.loginType = LoginType.GUEST_LOGIN
    return useZenniFetch<OtpLoginResBody>(`/redemption/api/user/login`, {
      method: 'POST',
      body: params,
      headers: {
        'x-zenni-store-id': params.storeId,
      },
    })
  }

  return {
    start,
    verify,
    guestLogin,
  }
}
