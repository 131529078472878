import { ref, reactive, computed } from 'vue'
import { defineStore } from 'pinia'
import { jwtDecode } from 'jwt-decode'
import { FetchError } from 'ofetch'
import { useUserClientStores } from '@/composables/api/useUserClientStores'
import { useGift } from '@/composables/api/useGift'
import { useOtp } from '@/composables/api/useOtp'
import type { CampaignGiftGetResponseBody } from '@/types/redemption'
import { persistedState } from '#imports'

export const useGuestStore = defineStore(
  'guest',
  () => {
    const guest = reactive<{
      email: string | undefined
      storeId: string | undefined
      userId: string | undefined
      token: string | undefined
      loggedInToken: string | undefined
    }>({
      email: '',
      storeId: '',
      userId: '',
      token: '',
      loggedInToken: '',
    })
    const gift = ref<CampaignGiftGetResponseBody | null>(null)
    const dependents = ref<CampaignGiftGetResponseBody[] | null>(null)
    const currentGiftId = ref<string>('')
    const currentGift = computed<CampaignGiftGetResponseBody | null>(() => {
      if (gift.value && gift.value.id === currentGiftId.value) {
        return gift.value
      }

      return dependents.value?.find((g) => g.id === currentGiftId.value) || null
    })
    const isSafetyCatalog = computed(
      () => currentGift.value?.policy?.catalogs?.includes('safety') || false,
    )

    const setCurrentGift = (targetGiftId: string) => {
      currentGiftId.value = targetGiftId
    }

    const setLoggedInToken = (token: string) => {
      guest.loggedInToken = token
    }

    const { switchStore } = useUserClientStores()
    const switchStoreToken = async (storeId: string) => {
      if (guest.loggedInToken) {
        requestError.value = null
        const { data, error } = await switchStore(storeId)
        if (data.value?.token) {
          setLoggedInToken(data.value.token || '')
        } else if (error) {
          requestError.value = error.value
        }
      }
    }

    const calculateGiftAmt = (g: CampaignGiftGetResponseBody): number => {
      if (g.usage?.type === 'freeItem') {
        return g.usage.budget ?? 0
      } else if (g.usage?.type === 'giftCard') {
        return g.usage.balance ?? 0
      }
      return 0
    }

    const giftAmount = computed(() =>
      gift.value ? calculateGiftAmt(gift.value) : 0,
    )

    const requestError = ref<FetchError | null>()

    const { getGiftById, getDependentGifts } = useGift()
    const { guestLogin } = useOtp()

    const loadDependents = async (email?: string) => {
      const emailToUse = email || guest.email || ''
      const { data, error } = await getDependentGifts(emailToUse)

      if (data.value) {
        dependents.value = []
        data.value.forEach((g) => {
          if (g.id !== gift.value?.id) {
            dependents.value?.push(g)
          }
        })
      } else if (error.value) {
        // eslint-disable-next-line no-console
        console.log(error.value)
      }
    }

    const loadGift = async (id: string) => {
      const { data, error } = await getGiftById(id)
      if (data.value) {
        gift.value = data.value
        ;({
          email: guest.email,
          storeId: guest.storeId,
          userId: guest.userId,
        } = data.value)

        if (!currentGiftId.value) {
          currentGiftId.value = data.value.id
        }
      } else if (error.value) {
        requestError.value = error.value
      }
    }

    const loginGuestUser = async () => {
      const { data, error } = await guestLogin({
        userEmail: guest.email ?? '',
        userId: guest.userId ?? '',
        storeId: guest.storeId ?? '',
      })

      if (data.value) {
        guest.token = data.value.token
      } else if (error.value) {
        requestError.value = error.value
      }
    }

    const reset = () => {
      gift.value = null
      currentGiftId.value = ''
      dependents.value = null
      guest.email = ''
      guest.storeId = ''
      guest.userId = ''
      guest.token = ''
      guest.loggedInToken = ''
      requestError.value = null
    }

    const primaryUser = computed<{
      firstName: string
      lastName: string
      email: string
    } | null>(() => {
      if (guest.loggedInToken) {
        const decodedToken = jwtDecode<{
          firstName: string
          lastName: string
          email: string
        }>(guest.loggedInToken)
        return decodedToken
      }
      return null
    })

    return {
      guest,
      primaryUser,
      gift,
      currentGiftId,
      currentGift,
      isSafetyCatalog,
      setCurrentGift,
      setLoggedInToken,
      switchStoreToken,
      dependents,
      giftAmount,
      requestError,
      loadGift,
      loadDependents,
      loginGuestUser,
      reset,
      calculateGiftAmt,
    }
  },
  {
    persist: [
      {
        storage: persistedState.cookiesWithOptions({
          sameSite: 'strict',
        }),
        paths: ['guest', 'gift', 'currentGiftId'],
      },
    ],
  },
)
