import type {
  CartCreateResponse,
  CartResponseSuccess,
  ShippingInformationRequest,
  EstimateShippingMethodsRequest,
  EstimateShippingResponse,
  AddItemsToCartRequest,
  UpdateBundleItemsRequest,
  CartUpdatedResponseSuccess,
} from '@/types/cart'
import { useZenniFetch } from '@/composables/useZenniFetch'
import { useGuestStore } from '~/store/guest'
import type { UseFetchOptions } from '#app'

export const useGuestCart = () => {
  const guestStore = useGuestStore()

  const create = (opts?: UseFetchOptions<CartCreateResponse>) => {
    return useZenniFetch<CartCreateResponse>(`/cart/api/v2/cart`, {
      method: 'POST',
      ...opts,
      headers: {
        'x-zenni-store-id': guestStore.guest?.storeId ?? '',
        'x-authorization-obo': `Bearer ${guestStore.guest?.token ?? ''}`,
      },
    })
  }

  const get = (id: string, opts?: UseFetchOptions<CartResponseSuccess>) => {
    return useZenniFetch<CartResponseSuccess>(`/cart/api/v2/cart/${id}`, {
      method: 'GET',
      ...opts,
      headers: {
        'x-zenni-store-id': guestStore.guest?.storeId ?? '',
        'x-authorization-obo': `Bearer ${guestStore.guest?.token ?? ''}`,
      },
    })
  }

  const addShipping = (
    id: string,
    params: ShippingInformationRequest,
    opts?: UseFetchOptions<CartUpdatedResponseSuccess>,
  ) => {
    return useZenniFetch<CartUpdatedResponseSuccess>(
      `/cart/api/v2/cart/${id}/shipping-information`,
      {
        method: 'POST',
        body: {
          addressInformation: params,
        },
        headers: {
          'x-zenni-store-id': guestStore.guest?.storeId ?? '',
          'x-authorization-obo': `Bearer ${guestStore.guest?.token ?? ''}`,
        },
        ...opts,
      },
    )
  }

  const estimateShipping = (
    id: string,
    params: EstimateShippingMethodsRequest,
    opts?: UseFetchOptions<EstimateShippingResponse>,
  ) => {
    return useZenniFetch<EstimateShippingResponse>(
      `/cart/api/v2/cart/${id}/estimate-shipping-methods`,
      {
        method: 'POST',
        body: params,
        headers: {
          'x-zenni-store-id': guestStore.guest?.storeId ?? '',
          'x-authorization-obo': `Bearer ${guestStore.guest?.token ?? ''}`,
        },
        ...opts,
      },
    )
  }

  const addItems = (
    id: string,
    items: AddItemsToCartRequest,
    opts?: UseFetchOptions<CartResponseSuccess>,
  ) => {
    return useZenniFetch<CartResponseSuccess>(`/cart/api/v2/cart/${id}/items`, {
      method: 'POST',
      body: items,
      headers: {
        'x-zenni-store-id': guestStore.guest?.storeId ?? '',
        'x-authorization-obo': `Bearer ${guestStore.guest?.token ?? ''}`,
      },
      ...opts,
    })
  }

  const updateBundle = (
    id: string,
    bundleId: string,
    params: UpdateBundleItemsRequest,
    opts?: UseFetchOptions<CartResponseSuccess>,
  ) => {
    return useZenniFetch<CartResponseSuccess>(
      `/cart/api/v2/cart/${id}/bundles/${bundleId}`,
      {
        method: 'PATCH',
        body: params,
        headers: {
          'x-zenni-store-id': guestStore.guest?.storeId ?? '',
          'x-authorization-obo': `Bearer ${guestStore.guest?.token ?? ''}`,
        },
        ...opts,
      },
    )
  }

  const removeBundle = (
    id: string,
    bundleId: string,
    opts?: UseFetchOptions<CartResponseSuccess>,
  ) => {
    return useZenniFetch<CartResponseSuccess>(
      `/cart/api/v2/cart/${id}/bundles/${bundleId}`,
      {
        method: 'DELETE',
        body: {},
        headers: {
          'x-zenni-store-id': guestStore.guest?.storeId ?? '',
          'x-authorization-obo': `Bearer ${guestStore.guest?.token ?? ''}`,
        },
        ...opts,
      },
    )
  }

  return {
    create,
    get,
    addItems,
    addShipping,
    estimateShipping,
    updateBundle,
    removeBundle,
  }
}
